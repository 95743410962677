.react-tel-input .flag-dropdown {
  display: none;
}

.paypal-button {
  background-image: -webkit-linear-gradient(#0070BA 20%, #0070BA) !important;
  background-image: linear-gradient(#0070BA 20%, #0070BA) !important;
  background-repeat: no-repeat !important;
  border-radius: 50px;
  border-color: #0070BA;
  margin: 10px 45px;
  white-space: nowrap;
  color: #FFFFFF;
  padding: 12px 24px;
  display: flex;
  span {
    margin-left: 9px;
  }
}

.radio-button {
  background: "#F5F5F5";
  border-radius: "16px";
}