.all-products-container {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: none;
}

.swiper-button-prev,
.swiper-button-next {
  width: auto;
}
