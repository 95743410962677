.product-container {
  ul {
    margin-bottom: 20px;
    padding: 0 0 0 20px;
    line-height: 1.8;
    list-style-type: disc;

    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }

  p {
    margin-bottom: 12px;
    font-size: 14px;
    color: #555555;
  }

  h3 {
    font-size: 14px;
    line-height: 1.75rem;
    font-weight: bold;
  }
}
