.store-container {
  strong {
    font-weight: bold;
  }

  p {
    margin: 6px 0px;
  }

  h1,
  h2,
  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px;
    clear: both;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

}
