.article-container {
  p {
    margin-bottom: 12px;
    line-height: 1.75rem;

    a:not(.product) {
      color: #0094ff;
    }
  }

  ul {
    margin-bottom: 20px;
    padding: 0 0 0 20px;
    line-height: 1.8;
    list-style-type: disc;

    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }

  .image-added img {
    border-radius: 16px;
  }

  h1,
  h2,
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 48px;
    margin-bottom: 6px;
    clear: both;
  }

  .product,
  .card {
    display: block;
    box-shadow: 0px 8px 16px 0px #0000001f;
    border-radius: 8px;
    padding: 16px;
    height: 100%;
  }

  .col-xs-12 > .card:only-child,
  .col-xs-12 > .product-card:only-child,
  p > .product:only-child,
  p > .card:only-child {
    width: 66%;
    margin: auto;
  }

  p > .product:only-child,
  p > .card:only-child {
    margin: 24px auto;
  }

  p > img, p > picture > img {
    width: 100%;
    margin: 24px auto;
    border-radius: 16px;
  }

  iframe {
    padding: 0 !important;
    border-radius: 16px;
  }

  .product.right.wrap {
    float: right;
    clear: both;
    margin: 0 0 16px 16px;
    display: block;
    width: 50%;
  }

  .card-row {
    display: flex;
    flex-flow: row;
    margin: 2rem 0;
    gap: 1rem;
    align-items: stretch;
    justify-content: center;
  }

  .store-card {
    text-align: center;

    img {
      margin-bottom: 2rem;
    }

    .cashback {
      font-weight: bold;
    }

    .btn-stack {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.5rem;

      .btn-info {
        background-color: black;
        color: white;
      }

      .btn {
        width: 100%;
        text-align: center;
        border-radius: 1.5rem;
        padding: 0.5rem 1rem;
      }
    }
  }

  .col-xs-12 {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
    margin-right: 12px;
    margin-bottom: 12px;
    width: 50%;
  }

  picture {
    display: flex;
    justify-content: center;
  }

  .card-fill {
    min-height: 250px;
    max-width: 300px;
    align-content: center;
    justify-self: center;
  }

  div:has(a) {
    justify-self: center;
}

  .card .card-fill img {
    max-width: 100%;
    border: 0;
    vertical-align: middle;
  }

  .product-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    display: block;
    border-top: 4px solid #ffc701;
    padding-top: 1rem;
    text-align: center;
  }

  .price {
    display: block;
    font-size: 14px;
    text-align: center;
  }

  .was_cashback {
    text-decoration: line-through;
    color: #ff6262;
  }

  .deal-content .cb-exclusive,
  .store .cb-exclusive,
  .store-block .cb-exclusive,
  .store-block .current-cash-back.cb-exclusive,
  .deal .cb-exclusive,
  .deal-block .cb-exclusive,
  .card .cb-exclusive {
    display: none;
  }

  .popover {
    display: none;
    width: max-content;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    color: black;
    font-weight: bold;
    padding: 12px;
    font-size: 90%;
    z-index: 50;
    cursor: default;
  }

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg);
    margin-top: 12px;
  }

  .table-responsive {
    border: none;
  }

  .table-responsive table {
    //border: 1px solid black;
  }

  .table {
    margin-bottom: 0;
  }

  thead {
    background: white;
    color: black;
    border: none;
    text-transform: uppercase;
  }

  table > thead > tr > th {
    border-bottom: 0;
  }

  table > tbody > tr > td {
    border-color: black;
  }

  table td.flush-right {
    text-align: right;
    padding-right: 0;
  }

  table .vertically-centered td {
    vertical-align: middle;
  }

  table .offer-price {
    .btn {
      float: right;
    }
  }

  div.popover-container {
    max-width: none;
    border: none;
    background-color: white;
    color: black;
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.2);
    min-width: 294px;
    border-radius: 8px;
    padding: 8px;

    .store-logo {
      max-height: 30px;
    }

    .popover-title {
      display: block;
      text-align: center;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .popover-content {
      padding: 0;

      table.table {
        padding: 9px 13px;
        margin: 0;
        background-color: white;

        thead {
          > tr > th {
            display: none;
          }
        }

        tbody tr td:only-of-type {
          text-align: left;
        }

        tr > td {
          vertical-align: middle;
          padding: 13px;
          color: black;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        tbody tr > td {
          position: relative;
          font-weight: bold;

          a {
            line-height: 1.25em;
            display: block;
            font-size: 12px;
          }

          a.btn {
            color: black;
            background-color: #ffc701;
            border-radius: 1.5rem;
            padding: 0.5rem 1rem;
          }

          &:first-child:before,
          &:last-child:after {
            content: "";
            position: absolute;
            bottom: -1px;
            width: 13px;
            height: 0px;
          }

          &:first-child:before {
            left: 0;
          }

          &:last-child:after {
            right: 0;
          }
        }

        tfoot {
          text-align: center;

          td {
            border: none;
          }
        }

        &.deals {
          width: 100%;
        }
      }
    }
  }

  .invalid-store {
    max-width: 300px
  }

  .deal-card {
    background: #fff;
    padding: 10%;
    text-align: center;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .card-fill {
      margin-top: auto;
    }

    .card-top {
      margin-bottom: auto;
    }

    .card-bottom {
      padding-top: 6px;
      margin-top: auto;
    }

    .was_cashback {
      font-size: 1.2rem;
      color: #555;
      height: 1.2em;
    }

    .strikethrough {
      text-decoration: line-through;
    }

    .cashback,
    .sm-cashback {
      margin-top: 3px;
      font-size: 22px;
      line-height: 1.2em;
    }

    .cashback {
      font-weight: bold;
      height: 2.6em;
    }

    .btn-stack {
      margin-top: 6px
    }

    .text-3-lines {
      height: 4em;
      line-height: 1.35em;
      overflow: hidden;
      font-weight: 600;
    }

    .card-cashback-red {

      .cashback,
      .sm-cashback {
        color: #f54a2e;
      }
    }

    .left-align .card-bottom {
      text-align: left;
    }

    .card-shadow.card,
    .card-shadow .card {
      box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      transition: box-shadow 0.35s ease;
    }
    
    .card-border.card,
    .card-border .card {
      border: 1px solid #c4c8ca;
      border-radius: 8px;
      transition: border 0.35s ease;
    }

    .card-border-dashed.card,
    .card-border-dashed .card {
      border: 2px dashed #606568;
      border-radius: 8px;
    }

    .no-space-card-border {
      border-bottom: 1px solid #c4c8ca;
      border-right: 1px solid #c4c8ca;
    }

    .no-space-card-border .card {
      border-top: 1px solid #c4c8ca;
      border-left: 1px solid #c4c8ca;
      border-bottom: none;
      border-right: none;
    }

    img {
      padding: 0% 20%;
    }

    .deal-code {
      padding-top: 8px;
    }
  }
}
